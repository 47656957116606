import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - Hints to make the job easier"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hints to make the job easier`}</h1>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Building your pergola will be easier if you prepare all tools and materials before you start assembly.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Weather-protect any wood that you have had to cut by staining, painting or using a wood preservative first before fixing them together.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Measuring is easy but materials are expensive. So, double check all measurements and markings before you cut any piece of wood.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`When fixing the posts to the post supports use temporary props to hold the posts upright to ensure they are perfectly vertical.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Remember planning permission may be required. Check with your local council first if you are unsure.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Whilst all the measurements on the 'Pergola Assembly' pages are accurate, in reality, working with wood means that you are unlikely to exactly replicate the dimensions, to the nearest millimetre, when you assemble the pergola on your chosen site. Therefore, wood is a great material with which to work with as you can adjust the process as you go along. Don't forget that a pergola is meant to look rustic and if a rafter, for example, turns out to be not entirely square with the next in line, don't worry - it all adds to the overall charm!`}</p>
      </li>
    </ol>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      